import { useEffect, useState } from "react";
import IWorkoutResponse from "../../../interfaces/workouts/IWorkoutResponse";
import { Button, Form, Spinner } from "react-bootstrap";
import { axiosContext, UseAppContext } from "../../../context/appContext";
import WorkoutWeek from "./WorkoutWeek";
import IWeek from "../../../interfaces/workouts/IWeek";
import { variant } from "../../../functions/workoutsFunctions";
import { FaCheck, FaEdit, FaShare, FaTrash } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  editClientWorkout,
  setCreating,
  setStoreCurrentWeeks,
  setStoreCurrentWorkout,
} from "../../../store/workoutReducer";
import IResponse from "../../../interfaces/IResponse";
import { MdCheck, MdClose } from "react-icons/md";
import { toast } from "react-toastify";
import DeleteWorkoutModal from "./DeleteWorkoutModal";
import LoadingDiv from "../../loading/LoadingDiv";

interface props {
  currentWorkout: IWorkoutResponse;
  selected?: boolean;
}

export default function WorkoutCard({ currentWorkout, selected }: props) {
  const [selectedWeek, setSelectedWeek] = useState(0);
  const { translate, isMobile, user } = UseAppContext();
  const currentWeeks = currentWorkout?.weeks || [];
  const [weekToShow, setWeekToShow] = useState(
    currentWeeks[selectedWeek] || ({} as IWeek)
  );
  const isProfessor = user.type === "professor";
  const [editingTitle, setEditingTitle] = useState(false);
  const [currentTitle, setCurrentTitle] = useState(currentWorkout.title || "");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { client } = useParams();
  const [showDelete, setShowDelete] = useState(false);

  const currentsWeeks = useSelector(
    (state: any) => state.workout.currentWeeks
  ) as Array<IWeek>;

  const creating = useSelector(
    (state: any) => state.workout.creating
  ) as Boolean;

  const loadingWorkouts = useSelector(
    (state: any) => state.workout.loading
  ) as Boolean;

  useEffect(() => {
    if (currentsWeeks && selectedWeek > -1) {
      setWeekToShow(currentsWeeks[selectedWeek]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentsWeeks]);

  useEffect(() => {
    async function validateGeneration() {
      if (
        currentWorkout?.simpleWeeks?.some((_sWorkout, index) =>
          haveToCreateWeek(index)
        )
      ) {
        generateWeeks();
      }
    }

    if (
      currentWorkout?.simpleWeeks &&
      currentWorkout?.weeks?.length < currentWorkout?.simpleWeeks?.length &&
      !creating
    ) {
      validateGeneration();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWorkout]);

  function haveToCreateWeek(position: number) {
    if (
      currentWorkout?.simpleWeeks &&
      (!currentWorkout?.simpleWeeks[position] ||
        currentWorkout?.weeks[position])
    ) {
      return false;
    }

    return true;
  }

  async function generateWeeks() {
    dispatch(setCreating(true));

    try {
      const response = await axiosContext.post(
        "/app/workouts/generateWorkoutWeeks",
        {
          langName: "Português de Brasil",
          id: currentWorkout?.id,
          client: currentWorkout?.client,
        }
      );
      const data = response.data as IResponse;
      if (data.success && data.data) {
        const newResponse = { ...data.data } as IWorkoutResponse;
        dispatch(setStoreCurrentWorkout(newResponse));
        dispatch(setStoreCurrentWeeks(newResponse.weeks));
        dispatch(setCreating(false));
        return newResponse;
      }
    } catch (error: any) {
      console.error("Error fetching data:", error);
      dispatch(setCreating(false));
    }

    dispatch(setCreating(false));

    return;
  }

  useEffect(() => {
    if (selectedWeek > -1) {
      if (currentsWeeks) {
        return setWeekToShow(currentsWeeks[selectedWeek]);
      }

      setWeekToShow(currentWorkout?.weeks[selectedWeek]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWeek]);

  async function confirmDeleteFunction() {
    setShowDelete(false);
    const response = await axiosContext.delete(
      `/app/workouts/deleteClientWorkout`,
      {
        params: {
          id: currentWorkout?.id,
          client: currentWorkout?.client,
        },
      }
    );
    if (response.data?.success) {
      toast.success(translate("successDelete"), {
        position: "top-center",
        autoClose: 1000,
        toastId: "currentUpdateWorkout",
      });

      navigate(`/client/${currentWorkout?.client}`);
    }
  }

  function updateWorkoutFunction() {
    if (!isProfessor) {
      return;
    }
    if (currentWorkout?.id) {
      const payload = {
        status: "",
        workoutId: currentWorkout.id,
        weeks: currentWorkout.weeks || ([] as Array<IWeek>),
        title: currentTitle,
        client: client,
      } as any;

      if (!loadingWorkouts && user.id) {
        payload.professor = user.id;
        return dispatch(editClientWorkout(payload) as any).then(() => {
          toast.success(translate("workoutUpdated"), {
            position: "top-center",
            autoClose: 1000,
            toastId: "currentUpdateWorkout",
          });
        });
      }
    }
  }

  async function updateWorkoutStatus(status: string) {
    if (!isProfessor) {
      return;
    }
    if (currentWorkout?.id) {
      const payload = {
        status: status,
        workoutId: currentWorkout.id,
        weeks: currentWorkout.weeks || ([] as Array<IWeek>),
        title: currentTitle,
        client: client,
      } as any;

      if (!loadingWorkouts && user.id) {
        payload.professor = user.id;
        return await dispatch(editClientWorkout(payload) as any).then(
          (e: any) => {
            console.error(e);

            console.error(e.status);
            console.error(status);

            toast.success(translate("workoutUpdated"), {
              position: "top-center",
              autoClose: 1000,
              toastId: "currentUpdateWorkout",
            });

            if (e.payload.status === status) {
              window.location.reload();
            }
          }
        );
      }
    }
  }

  return (
    <div className={"border rounded mb-2 bg-white text-white"}>
      <div className="py-2 bg-primary rounded-top">
        <h6
          className={"my-1 " + (isMobile ? "text-14 weigth-normal" : "")}
          style={{}}
        >
          {editingTitle ? (
            <div className="d-flex px-2">
              <Form.Control
                type="text"
                name="title"
                as={isMobile ? "textarea" : undefined}
                value={currentTitle}
                onChange={(e) => {
                  setCurrentTitle(e.target?.value || "");
                }}
              />
              <Button
                size="sm"
                disabled={currentTitle === currentWorkout.title}
                className="ms-1"
                variant="contrast"
                style={{ maxHeight: "35px" }}
                onClick={() => {
                  updateWorkoutFunction();
                  setEditingTitle(false);
                }}
              >
                {isMobile ? <MdCheck /> : translate("edit")}
              </Button>
              <Button
                size="sm"
                className="ms-1"
                variant="contrast"
                style={{ maxHeight: "35px" }}
                onClick={() => {
                  setEditingTitle(false);
                }}
              >
                {isMobile ? <MdClose /> : translate("cancel")}
              </Button>
            </div>
          ) : (
            <div className="d-flex">
              {isProfessor && !creating && (
                <Button
                  size="sm"
                  className={"d-flex " + (isMobile ? "ms-0" : "ms-2")}
                  onClick={() => {
                    setCurrentTitle(currentWorkout.title || "");
                    setEditingTitle(true);
                  }}
                >
                  <FaEdit />
                </Button>
              )}
              <span
                className={"m-auto"}
                style={{ marginRight: isMobile ? undefined : "32px" }}
              >
                {currentWorkout.status === "DRAFT" &&
                  "(" + translate(currentWorkout.status) + ") "}
                {currentWorkout.title}
              </span>

              {isProfessor && !creating && (
                <Button
                  size="sm"
                  className={"d-flex " + (isMobile ? "me-1" : "me-2")}
                  onClick={() => {
                    setShowDelete(true);
                  }}
                >
                  <FaTrash />
                </Button>
              )}
            </div>
          )}
        </h6>
      </div>

      {!creating && currentWorkout.status && isProfessor && (
        <div className="bg-secondary py-1 d-flex text-black">
          {loadingWorkouts ? (
            <LoadingDiv height="30px" />
          ) : (
            <>
              <div className="px-2 d-flex">
                <p className="my-auto">{translate(currentWorkout.status)}</p>
              </div>
              <div className="w-100 text-end pe-2">
                {!creating &&
                  currentWorkout.status === "DRAFT" &&
                  isProfessor && (
                    <Button
                      variant="success"
                      className=""
                      size="sm"
                      onClick={() => {
                        updateWorkoutStatus("ACTIVE");
                      }}
                    >
                      {translate("publish")},{" "}
                      {translate("sendToClient").toLowerCase()}{" "}
                      <FaShare className="ms-2" />
                    </Button>
                  )}

                {!creating &&
                  currentWorkout.status === "ACTIVE" &&
                  isProfessor && (
                    <Button
                      variant="danger"
                      className=""
                      size="sm"
                      onClick={() => {
                        updateWorkoutStatus("DRAFT");
                      }}
                    >
                      {translate("pause")},{" "}
                      {translate("removeFromClient").toLowerCase()}{" "}
                      <MdClose className="ms-2" />
                    </Button>
                  )}
              </div>
            </>
          )}
        </div>
      )}

      <div className={"bg-white text-black px-2 pb-3"}>
        {creating && (
          <div className="mt-2">
            <Spinner size="sm" className="mx-auto" />
            <p>{translate("loadingWeeksData")}</p>
          </div>
        )}

        <div className="mt-2">
          <div className="mb-2 text-start">
            <span>
              {translate("week")}:{" "}
              {(currentsWeeks || currentWorkout.weeks).map((week, position) => {
                return (
                  <Button
                    key={`${currentWorkout.id}-week-${week.position}-${position}`}
                    variant={
                      position === selectedWeek
                        ? variant(week.done)
                        : "outline-" + variant(week.done)
                    }
                    className="mx-1"
                    size="sm"
                    style={{ minWidth: "35px" }}
                    onClick={() => {
                      setSelectedWeek(
                        selectedWeek === position ? -1 : position
                      );
                    }}
                  >
                    {position + 1}{" "}
                    {week.done && <FaCheck size={7} className="ms-0" />}
                  </Button>
                );
              })}
            </span>
          </div>

          {selectedWeek > -1 && (
            <WorkoutWeek
              week={weekToShow}
              weeks={currentsWeeks}
              selectedWeek={selectedWeek}
            />
          )}
        </div>
      </div>

      <DeleteWorkoutModal
        show={showDelete}
        hide={() => {
          setShowDelete(false);
        }}
        confirm={() => {
          confirmDeleteFunction();
        }}
      />
    </div>
  );
}
