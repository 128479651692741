import { Button, Form } from "react-bootstrap";
import { axiosContext, UseAppContext } from "../../../context/appContext";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import IResponse from "../../../interfaces/IResponse";
import { useDispatch, useSelector } from "react-redux";
import ISubscription from "../../../interfaces/subscriptions/ISubscription";
import { fetchSubscriptionWorkouts } from "../../../store/workoutReducer";

interface props {
  hide: () => void;
}

export default function SubscribeForm({ hide }: props) {
  const { translate, user } = UseAppContext();
  const [searchParams] = useSearchParams();
  const professorParam = searchParams.get("professor");
  const pinParam = searchParams.get("pin");
  const dispatch = useDispatch();
  const subcriptions = useSelector(
    (state: any) => state.user.subcriptions
  ) as Array<ISubscription>;

  const [professor, setProfessor] = useState(professorParam || "");
  const [pin, setPin] = useState(pinParam || "");

  useEffect(() => {
    if (
      subcriptions?.find(
        (subcription) => subcription.professorLink === professorParam
      )
    ) {
      hide();
      window.history.replaceState({}, document.title, "/subscription");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subcriptions, professorParam]);

  async function handleSubcribe() {
    if (!pin?.length || !professor?.length) {
      toast.error(translate("emptyError"), {
        position: "top-center",
        autoClose: 3000,
      });
    }

    try {
      const response = await axiosContext.post("/app/subcription/subscribe", {
        pin,
        professor,
      });
      const data = response.data as IResponse;
      if (data.success) {
        toast.success(translate(data.info), {
          position: "top-center",
          autoClose: 3000,
        });

        await dispatch(
          fetchSubscriptionWorkouts({
            user: user?.id || "",
            professor: professor || "",
          }) as any
        );
        window.history.replaceState({}, document.title, "/subscription");
        hide();
        window.location.reload();
      } else {
        toast.error(translate(data.info), {
          position: "top-center",
          autoClose: 3000,
        });
      }
    } catch (error: any) {
      toast.error(translate(error), {
        position: "top-center",
        autoClose: 3000,
      });
    }
  }

  return (
    <div className="d-flex flex-column">
      <h4 className="text-center">{translate("subscribe")}</h4>

      <p>{translate("howToSubscribe")}</p>

      <Form.Group controlId="professor">
        <Form.Label className="mb-1">{translate("professorLink")}</Form.Label>
        <Form.Control
          type="text"
          name="professor"
          value={professor}
          onChange={(e) => {
            setProfessor(e.currentTarget.value || "");
          }}
        />
      </Form.Group>

      <Form.Group controlId="pin">
        <Form.Label className="mb-1">{translate("pin")}</Form.Label>
        <Form.Control
          type="text"
          name="pin"
          value={pin}
          onChange={(e) => {
            setPin(e.currentTarget.value || "");
          }}
        />
      </Form.Group>

      <div className="d-flex mt-3">
        <Button
          variant="primary"
          className="mx-auto"
          onClick={() => {
            handleSubcribe();
          }}
        >
          {translate("subscribe")}
        </Button>
      </div>
    </div>
  );
}
